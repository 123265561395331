<template>
  <div class="loyalty-notify">
    <KTCodePreview v-bind:title="'Thông báo loyalty'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <router-link to="/loyalty-notifies/upsert">
              <button
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-table :items="mainList.dataset" :fields="fields" bordered>
          <template v-slot:cell(isActive)="row">
            <span v-if="row.item.isActive">
              <b-badge variant="success">Hoạt động</b-badge>
            </span>
            <span v-else>
              <b-badge variant="danger">Ngừng hoạt động</b-badge>
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-dropdown size="sm" id="dropdown-left" no-caret right>
              <template slot="button-content">
                <i
                  style="font-size: 1rem; padding-right: 0px"
                  class="flaticon2-settings"
                ></i>
              </template>
              <b-dropdown-item @click="editItem(row.item.id)">
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-pen icon-color"
                  ></i>
                  &nbsp; Chỉnh sửa
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="sendNotify(row.item.id)">
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-paperplane text-success"
                  ></i>
                  &nbsp; Gửi thông báo
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                <span style="color: #3f4254; font-size: 12px">
                  <i
                    style="font-size: 1rem; color: #d33"
                    class="flaticon2-rubbish-bin-delete-button"
                  ></i>
                  &nbsp; Xóa
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      fields: [
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'title',
          label: 'Chương trình',
        },
        {
          key: 'content',
          label: 'Nội dung',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      onLoadingList: false,
      apiParams: {
        page: 1,
        size: 20,
      },
    };
  },
  created() {
    this.fetchMainData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thông báo loyalty', route: 'loyalty-notifies' },
    ]);
  },
  methods: {
    fetchMainData: async function (page) {
      this.apiParams.page = page ? page : 1;
      this.apiParams.mode = this.$route.params.mode;

      this.onLoadingList = true;
      ApiService.query('/loyalty-notifies', {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onLoadingList = false;
          this.$nprogress.done();
        });
    },
    editItem: function (id) {
      this.$router.push({
        name: 'upsert-loyalty-notifies',
        params: { id: id },
      });
    },
    sendNotify: function (id) {
      ApiService.post(`/loyalty-notifies/send-notify/`, {
        id: id,
      })
        .then(() => {
          makeToastSuccess('Gửi thông báo thành công');
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
  },
};
</script>
<style lang="scss">
.loyalty-notify {
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>
